<template>
  <div class="layout">
    <div class="body">
      <img class="layout-top-left" src="./images/momo_layout_top_left.png" />
      <img
        class="layout-bottom-left"
        src="./images/momo_layout_bottom_left.png"
      />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      $error() {
        //
      },
    };
  },
};
</script>

<style lang="scss">
body {
  font-family: "Noto Sans TC", "Source Sans Pro", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
}

.layout {
  position: relative;
  padding-top: 84px;
  background: #35694b;

  .body {
    padding: 30px 15px;
    min-height: calc(100vh - 84px);
    max-width: 576px;
    margin: auto;
    background: #fff;
    position: relative;
  }

  .layout-top-left {
    position: absolute;
    top: 0;
    right: 0;
  }

  .layout-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
  }
}

.layoutMomo-title {
  width: 35%;
}

.home {
  width: 30%;
  margin-right: 10px;
}
</style>
